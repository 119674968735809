import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as indexWk8Hi46KRAMeta } from "/vercel/path0/pages/app/index.vue?macro=true";
import { default as _91id_93mqIywidjcrMeta } from "/vercel/path0/pages/app/reports/[id].vue?macro=true";
import { default as _91id_93m9ijYZC9y1Meta } from "/vercel/path0/pages/app/transaction/[id].vue?macro=true";
import { default as indexFDYPRiOAmdMeta } from "/vercel/path0/pages/app/user/index.vue?macro=true";
import { default as postcheckoutNqNKYsdtKUMeta } from "/vercel/path0/pages/app/user/postcheckout.vue?macro=true";
import { default as settingswajhRlA5CiMeta } from "/vercel/path0/pages/app/user/settings.vue?macro=true";
import { default as setup0za9ePb6UtMeta } from "/vercel/path0/pages/app/user/setup.vue?macro=true";
import { default as blog3R7VDb7RegMeta } from "/vercel/path0/pages/blog.vue?macro=true";
import { default as errorTD3gxBm2RgMeta } from "/vercel/path0/pages/error.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as signin0XCmIKo45IMeta } from "/vercel/path0/pages/signin.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/vercel/path0/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: "app-reports-id",
    path: "/app/reports/:id()",
    meta: _91id_93mqIywidjcrMeta || {},
    component: () => import("/vercel/path0/pages/app/reports/[id].vue").then(m => m.default || m)
  },
  {
    name: "app-transaction-id",
    path: "/app/transaction/:id()",
    meta: _91id_93m9ijYZC9y1Meta || {},
    component: () => import("/vercel/path0/pages/app/transaction/[id].vue").then(m => m.default || m)
  },
  {
    name: "app-user",
    path: "/app/user",
    meta: indexFDYPRiOAmdMeta || {},
    component: () => import("/vercel/path0/pages/app/user/index.vue").then(m => m.default || m)
  },
  {
    name: "app-user-postcheckout",
    path: "/app/user/postcheckout",
    meta: postcheckoutNqNKYsdtKUMeta || {},
    component: () => import("/vercel/path0/pages/app/user/postcheckout.vue").then(m => m.default || m)
  },
  {
    name: "app-user-settings",
    path: "/app/user/settings",
    meta: settingswajhRlA5CiMeta || {},
    component: () => import("/vercel/path0/pages/app/user/settings.vue").then(m => m.default || m)
  },
  {
    name: "app-user-setup",
    path: "/app/user/setup",
    meta: setup0za9ePb6UtMeta || {},
    component: () => import("/vercel/path0/pages/app/user/setup.vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/vercel/path0/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/vercel/path0/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "signin",
    path: "/signin",
    component: () => import("/vercel/path0/pages/signin.vue").then(m => m.default || m)
  }
]